var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("People")]),
    _c("p", { staticClass: "member-count" }, [
      _vm._v("(" + _vm._s(_vm.members.total) + " members)")
    ]),
    _c(
      "div",
      { staticClass: "member-list-container" },
      _vm._l(_vm.members.list, function(member) {
        return _c(
          "span",
          { key: member.id, staticClass: "member-container" },
          [_c("avatar", { attrs: { image: member.avatar, size: "48px" } })],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }