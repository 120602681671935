var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_c("app-header")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-card",
        { staticClass: "page-content mt-5", attrs: { rounded: "xl" } },
        [
          _c("org-detail-card", {
            attrs: { org: _vm.org, "is-member": _vm.isMember }
          }),
          _c(
            "div",
            { staticClass: "org-menu" },
            [
              _c("share-link-dialog", {
                attrs: { shareUrl: _vm.shareUrl, toolTipPlace: "left" }
              }),
              _c(
                "div",
                { staticClass: "mt-2" },
                _vm._l(_vm.orgMenuItems, function(item) {
                  return _c(
                    "div",
                    { key: item.label },
                    [
                      item.isDisplay
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { left: "", "open-on-hover": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        item.link
                                          ? _c(
                                              "router-link",
                                              { attrs: { to: item.link } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "pa-1",
                                                        attrs: {
                                                          color: "inqliPurple",
                                                          "aria-label":
                                                            item.ariaLabel
                                                        }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(_vm._s(item.icon))]
                                                )
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "pa-1",
                                                    attrs: {
                                                      color: "inqliPurple",
                                                      "aria-label":
                                                        item.ariaLabel
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return item.action(
                                                          _vm.org
                                                        )
                                                      }
                                                    }
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(_vm._s(item.icon))]
                                            )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v(_vm._s(item.label))])]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          ),
          _vm.members
            ? _c("member-list", {
                staticClass: "mt-5",
                attrs: { members: _vm.members }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }