<template>
  <layout>
    <template #header>
      <app-header />
    </template>
    <v-card class="page-content mt-5" rounded="xl">
      <org-detail-card :org="org" :is-member="isMember" />
      <div class="org-menu">
        <share-link-dialog :shareUrl="shareUrl" toolTipPlace="left" />
        <div class="mt-2">
          <div v-for="item in orgMenuItems" :key="item.label">
            <v-tooltip left open-on-hover v-if="item.isDisplay">
              <template v-slot:activator="{ on, attrs }">
                <router-link :to="item.link" v-if="item.link">
                  <v-icon
                    color="inqliPurple"
                    class="pa-1"
                    v-bind="attrs"
                    v-on="on"
                    :aria-label="item.ariaLabel"
                    >{{ item.icon }}</v-icon
                  >
                </router-link>
                <v-icon
                  v-else
                  color="inqliPurple"
                  @click="item.action(org)"
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  :aria-label="item.ariaLabel"
                  >{{ item.icon }}</v-icon
                >
              </template>
              <span>{{ item.label }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <member-list class="mt-5" :members="members" v-if="members" />
    </v-card>
  </layout>
</template>
<script>
import Layout from "../app/components/layouts/DefaultLayout.vue";
import Header from "../app/components/headers/DefaultHeader";
import OrgDetailCard from "./components/OrgDetailCard.vue";
import ShareLinkDialog from "../app/components/dialogs/ShareLinkDialog.vue";
import MemberList from "./components/MemberShortAvatarList.vue";
import { getOrg } from "./utils/org-request.js";
import { getUserRelationshipWithOrg } from "./utils/org-member-request";

export default {
  name: "OrgDetail",
  components: {
    layout: Layout,
    "app-header": Header,
    "org-detail-card": OrgDetailCard,
    "member-list": MemberList,
    "share-link-dialog": ShareLinkDialog,
  },
  metaInfo() {
    return {
      title: this.org.name,
      meta: [
        {
          name: "description",
          content: this.org.description,
        },
      ],
    };
  },
  data() {
    return {
      org: {},
      userRelationship: null,
      isAdmin: false,
      memberDisplayLimit: 10,
    };
  },
  computed: {
    members() {
      return this.org.members;
    },
    shareUrl() {
      return `${window.location.origin}/organizations/${this.org.id}`;
    },
    orgMenuItems() {
      return [
        {
          label: "Insights",
          icon: "mdi-chart-timeline-variant-shimmer",
          link: `/organizations/insights/${this.org.id}`,
          ariaLabel: "insights",
          isDisplay: this.isMember,
        },
        {
          label: "Edit profile",
          icon: "mdi-pencil",
          ariaLabel: "edit-org",
          link: `/organizations/edit/${this.org.id}`,
          isDisplay: this.isAdmin || this.isSystemAdmin,
        },
      ];
    },
    isMember() {
      return Boolean(this.userRelationship || this.isSystemAdmin);
    },
    isSystemAdmin() {
      return this.$store.state.user.isSystemAdmin;
    },
    currentUserId() {
      if (this.$store.state.user.isLogin) {
        return this.$store.state.user.username;
      }
      return null;
    },
    editOrgLink() {
      return `/organizations/edit/${this.org.id}`;
    },
  },
  watch: {
    userRelationship() {
      if (this.userRelationship) {
        this.isAdmin = this.userRelationship.is_admin;
      }
    },
  },
  async mounted() {
    const orgId = this.$route.params.orgId;
    await this.getOrg(orgId);
    const isLogin = this.$store.state.user.isLogin;
    if (isLogin) {
      this.getCurrentUserRelationship(orgId);
    }
  },
  methods: {
    async getOrg(orgId) {
      try {
        this.org = await getOrg(orgId, "members");
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting organization details."
        );
      }
    },
    async getCurrentUserRelationship(orgId) {
      try {
        const userRelationship = await getUserRelationshipWithOrg(
          this.currentUserId,
          orgId
        );
        this.userRelationship = userRelationship;
      } catch (error) {
        this.userRelationship = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.org-menu {
  position: absolute;
  top: 30px;
  right: 15px;
}
.page-content {
  max-width: 700px;
  width: 70%;
  flex: 1;
  margin: 0 auto;
  margin-top: 30px;
  padding: 20px 48px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .page-content {
    width: 95%;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .org-menu {
    top: 30px;
    right: 5px;
  }
}
</style>
