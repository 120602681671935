<template>
  <div>
    <h2>People</h2>
    <p class="member-count">({{ members.total }} members)</p>
    <div class="member-list-container">
      <span
        v-for="member in members.list"
        :key="member.id"
        class="member-container"
      >
        <avatar :image="member.avatar" size="48px" />
      </span>
    </div>
  </div>
</template>
<script>
import Avatar from "../../app/components/avatars/Avatar.vue";

export default {
  name: "MemberList",
  components: {
    avatar: Avatar,
  },
  props: {
    members: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.member-count {
  color: $inqliDarkGray;
  font-weight: 500;
  font-size: 12px;
}
.member-list-container {
  width: 100%;
}
.member-container {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
}
.member-container:not(:first-child) {
  margin-left: -64px;
  -webkit-mask: radial-gradient(
    circle 31px at 9px 50%,
    transparent 99%,
    #fff 100%
  );
  mask: radial-gradient(circle 31px at 9px 50%, transparent 99%, #fff 100%);
}
.edit-member-button {
  position: absolute;
  right: 1px;
  top: -10px;
}
</style>
